<template>
  <el-container class="container" v-if="!showEvento">
    
    <side-bar :header="'Calendário'" v-if="trocarTela === 'Eventos' || windowWidth > 1000">
      <template v-slot:side-menu>
        <div v-if="eventosSelecionados" class="b_e">
          <h3>Eventos: {{ diaSelecionado }}</h3>
          <div
            v-for="(e, i) in eventosSelecionados"
            :key="i"
            class="container-eventos"
            @click="abrirEvento(e)"
          >
            <div class="box-evento">
              <div style="display: flex">
                <div class="bar" :style="barColor(e)" />
                <div class="nomeEventoBox" style="margin-left: 10px">
                  <p class="nomeEvento">{{ e.nome }}</p>
                  <span> {{ detalhesEvento(e) }} </span>
                </div>
              </div>
              <i class="el-icon-arrow-right" />
            </div>
          </div>
          <div v-if="dataSemEvento" class="box-sem-evento">
            <p>Esta data não possui nenhum evento</p>
          </div>

          <div class="novoEventoBox">
            <el-button
              @click="showCriarEvento = true"
              :class="{ shake: disabled }"
              v-if="isRole('ROLE_ORGANIZACAO')"
              icon="el-icon-plus"
              class="bt-novo-evento"
              size="small"
              >Criar novo evento</el-button
            >
          </div>
        </div>
      </template>
    </side-bar>
    <div class="conteudo" v-if="trocarTela === 'Calendário' || windowWidth > 1000">
      <div style="padding: 10px 20px;">
        <calendario ref="calendario" :options="calendarOptions" />
      </div>
    </div>
    <novo-evento
      v-if="showCriarEvento"
      :visible="showCriarEvento"
      :dataSelecionada="diaSelecionado"
      @closeModal="showCriarEvento = false"
      @abrirChat="tratarEmit"
    />
    <el-radio-group v-model="trocarTela" fill="#0060b3" size="large" class="trocar-tela-mobile">
      <el-radio-button  label="Calendário" />
      <el-radio-button  label="Eventos" />
    </el-radio-group>
  </el-container>
  <el-container v-else>
    <info-evento :data="eventoSelecionado" @emit="tratarEmit" />
  </el-container>
</template>

<script>
import "../css/theme.css";
import calendario from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import KonnectService from "../services/KonnectEnterpriseService";
import infoEvento from "./InfoEvento.vue";
import novoEvento from "./NovoEvento.vue";
import moment from "moment";
import AuthUtils from "../utils/AuthUtils.js";
import AuthService from "../services/AuthService";
import tinyColor from "tinycolor2";
import _ from "lodash";
import SideSlider from "../components/Konnect/SideSlider.vue";
import SideBar from "./UserBar/SideBar.vue";

export default {
  name: "Calendario",
  components: { calendario, infoEvento, novoEvento, SideSlider, SideBar },
  data() {
    return {
      isRole: AuthUtils.isRole,

      showEvento: false,
      showCriarEvento: false,
      dataSemEvento: true,

      eventos: [],
      eventoSelecionado: null,
      eventosSelecionados: [],
      diaSelecionado: null,

      disabled: false,

      calendarOptions: {
        locale: "pt-br",
        height: "61vh",
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        buttonText: { today: "Hoje" },
        headerToolbar: {
          start: "prev",
          center: "title",
          end: "next",
        },
        selectable: true,
        events: [],
        dateClick: (i) => {this.exibirEventos(i); this.trocarTela = "Eventos"},
      },
      windowWidth: window.innerWidth,
      trocarTela: "Calendário"
    };
  },

  computed: {
    nomeUsuarioLogado() {
      return JSON?.parse(window?.localStorage?.getItem("usuario"))?.nome;
    },
  },

  mounted() {
    if (!window.localStorage.token) this.$router.push("/login");
    else {
      this.getEventos();
      this.diaSelecionado = moment(new Date()).format("DD MMMM YYYY");
    }
    
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    
  },

  methods: {
    logout() {
      AuthService.logout();
    },
    getEventos() {
      let events = [];
      this.eventosSelecionados = [];
      KonnectService.getCanais("eventos")
        .then((r) => {
          this.eventos = r.data;
          this.exibirEventos(moment(new Date()).format("YYYY-MM-DD"));
          r.data.forEach((e) => {
            let corDoEvento = e.categoria ? e.categoria.cor : "#0060B3";
            events.push({
              id: e._id,
              //title: e.nome,
              start: e.dataDoEvento,
              allDay: true,
              backgroundColor: corDoEvento,
              borderColor: corDoEvento,
              textColor: "red",
              classNames: ["calendario__categoria-evento"],
            });
          });
        })
        .catch((e) => console.log(e));
      this.calendarOptions.events = events;
    },

    barColor(e) {
      if (e.categoria == null || e.categoria == undefined)
        return `background: #0060B3`;
      else return `background: ${e.categoria.cor}`;
    },

    detalhesEvento(e) {
      let dt = moment(e.dataDoEvento).format("DD/MM/YYYY");
      if (!e.categoria) return `${dt}`;
      else return `${dt} | ${e.categoria.texto}`;
    },

    exibirEventos(i) {
      let d = i.dateStr ? i.dateStr : i;
      this.disabled = true
      setTimeout(() => {
        this.disabled = false
      }, 1500)
      this.diaSelecionado = moment(d).format("DD MMMM YYYY");
      this.eventosSelecionados = [];
      this.eventos.forEach((e) => {
        let dt = moment(e.dataDoEvento).format("YYYY-MM-DD");
        if (d == dt) this.eventosSelecionados.push(e);
      });
      this.$nextTick(() => {
        if (this.eventosSelecionados.length == 0) this.dataSemEvento = true;
        else this.dataSemEvento = false;
      });
    },

    abrirEvento(e) {
      this.eventoSelecionado = e;
      this.showEvento = true;
    },

    tratarEmit(val) {
      if (val == 1) this.getEventos();
      else {
        this.showEvento = false;
        this.eventoSelecionado = null;
        this.getEventos();
      }
    },
    
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },
};
</script>
<style scoped>
.conteudo {
  width: 100%;
}

>>> .b_e {
  padding: 10px 20px;
  text-align: start;
  min-width: 300px;
}

>>> .b_e h3 {
  color: #b9b9b9;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
}

>>> .box-evento {
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  padding: 10px 15px;
  margin-top: 10px;
  background: #ffff;
  display: flex;
  box-shadow: 1px 1px 4px #dddddd;
  justify-content: space-between;
  align-items: center;
  
}

>>> .box-evento p {
  color: #656565;
  font-size: 16px;
  word-break: break-word;
}

>>> .box-evento span {
  color: #a4a4a4;
  font-size: 12px;
}

>>> .bar {
  width: 4px;
  border-radius: 5px;
}

>>> .el-icon-arrow-right {
  font-size: 15px;
  color: #d4d4d4;
  margin-left: 15px;
}

>>> .box-sem-evento {
  text-align: center;
  margin: 10px;
}

>>> .novoEventoBox {
  text-align: center;
  margin: 10px;
}

>>> .box-sem-evento p {
  color: #f56c6c;
  font-size: 12px;
  margin: 20px;
}


.monitor {
  margin: 0px 0px 0px 25px !important;
}
/* SIDEBAR */
.sidebarContatos {
  display: flex;
  height: 100%;
}
.el-menu {
  border: none;
  background-color: unset!important;
}
.menu__externo {
  margin-top: 20vh;
}

.el-menu-item {
  height: auto !important;
  padding-bottom: 2vh;
}
.menu-link {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 30px !important;
}


span {
  display: block;
}

.bt-novo-evento {
  border: 1px solid #33cccc !important;
  color: #33cccc !important;
}

.bt-novo-evento span {
  font-weight: 500 !important;
}

>>> .el-icon-plus {
  font-weight: 600 !important;
}

/* CALENDÁRIO */
>>> .fc .fc-toolbar-title {
  font-size: 17px !important;
  color: #03283f;
}
>>> .fc .fc-scrollgrid-liquid {
  font-size: 14px !important;
}

>>> .fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 5px !important;
}

>>> .fc .fc-col-header-cell-cushion {
  color: #03283f;
}

>>> .fc,
>>> .fc *,
>>> .fc *:before,
>>> .fc *:after {
  text-transform: capitalize !important;
  font-weight: 500 !important;
}

>>> .fc .fc-button-primary {
  color: #33cccc !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

>>> .fc-daygrid-event {
  width: 7px !important;
  height: 7px !important;
  border-radius: 50% !important;
}

>>> .fc .fc-daygrid-day-top {
  justify-content: center !important;
}

>>> .fc-daygrid-day-number:hover {
  background-color: #d8fefe !important;
}

>>> .fc-daygrid-day-number:active {
  background-color: #dbfcf7 !important;
}

>>> .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  display: flex !important;
  justify-content: center !important;
}

>>> .fc .fc-daygrid-day-bottom {
  padding: 0 !important;
}

>>> .fc-daygrid-day {
  color: #656565 !important;
  font-weight: 400 !important;
  font-size: 13px !important;
}
>>> .fc-day-today {
  background: #fff !important ;
  color: #33cccc !important;
  font-weight: 500 !important;
}
>>> .fc .fc-button-primary:focus {
  box-shadow: none !important;
}

/* ELEMENT UI  */
>>> .el-main {
  padding: 0px !important;
}

>>> .el-button {
  border: none !important;
}
>>> .el-divider--horizontal {
  margin: 0 !important;
}

>>> .el-date-table >>> td.current:not(.disabled) >>> span {
  background-color: #33cccc !important;
  color: #fff !important;
}

>>> .el-date-table >>> td >>> .today >>> span,
>>> .el-select-dropdown__item.selected {
  color: #33cccc !important;
}

>>> .av {
  color: #0060b3;
}

>>> .el-header {
  padding: 0 !important;
}

.container {
  display: flex;
  position: relative;
}

/* >>> .user-info {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

>>> .user-info span {
  margin-top: 10px;
  color: #fff;
}

>>> #home {
  padding-left: 29px;
}

>>> #home span {
  color: #03283f;
  font-size: 15px;
  font-weight: 600;
} */

>>> .header-chat span {
  font-weight: 600;
  font-size: 17px;
  color: #03283f;
}

>>> .p,
>>> .g,
>>> .e {
  color: #fff;
  font-size: 18px;
}

.trocar-tela-mobile{
    margin-top: 10px;
    margin-bottom: 10px;
  }


 @media screen and (max-width: 1000px) {

      .container {
         flex-direction: column-reverse;
         flex:0;
         height: 100vh;
      }

      >>> .b_e {
        text-align: center;
        margin:auto;
        min-height: 170px;  
      }
      
   }
@media screen and (min-width: 1001px) {

  .trocar-tela-mobile{
    display: none;
  }

}

.shake {
  animation: shake 0.30s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  0% {  transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0deg); background-color: #14538c;}
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}
</style>
